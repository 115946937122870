import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Youtube Videostreaming from your Camera",
  "path": "/Advanced_User/Youtube_Videostreaming_from_your_Camera/",
  "dateChanged": "2020-03-20",
  "author": "Mike Polinowski",
  "excerpt": "You can use the free, open source to broadcast your cameras live stream to video streaming platforms like Youtube or Twitch.",
  "image": "./AU_SearchThumb_Youtube.png",
  "social": "/images/Search/AU_SearchThumb_Youtube.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Youtube_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Youtube Videostreaming from your Camera' dateChanged='2020-03-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='You can use the free, open source to broadcast your cameras live stream to video streaming platforms like Youtube or Twitch.' image='/images/Search/AU_SearchThumb_Youtube.png' twitter='/images/Search/AU_SearchThumb_Youtube.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Youtube_Videostreaming_from_your_Camera/' locationFR='/fr/Advanced_User/Youtube_Videostreaming_from_your_Camera/' crumbLabel="Streaming" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "youtube-videostreaming-from-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#youtube-videostreaming-from-your-camera",
        "aria-label": "youtube videostreaming from your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Youtube Videostreaming from your Camera`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installing-obs"
        }}>{`Installing OBS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-your-cameras"
        }}>{`Adding your Cameras`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-to-your-youtube-account"
        }}>{`Connecting to your Youtube Account`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#vlc-video-source"
        }}>{`VLC Video Source`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`You can use the free, open source `}<a parentName="p" {...{
        "href": "https://github.com/obsproject/obs-studio/wiki/Install-Instructions"
      }}>{`OBS software`}</a>{` to broadcast your cameras live stream to video streaming platforms like Youtube or Twitch.`}</p>
    <h2 {...{
      "id": "installing-obs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installing-obs",
        "aria-label": "installing obs permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing OBS`}</h2>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://github.com/obsproject/obs-studio/releases/"
      }}>{`download the latest version of OBS`}</a>{` from Github. Currently this is version 25:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "813px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/52ddee8caedd1e902c0f973f1ba06521/baaa6/INSTAR_IP_Camera_Youtube_Streaming_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7CAAAOwgEVKEqAAAABFklEQVQoz43Qy26CQBSAYd6ki1ZFBASGi6AocpGLIkpbae22z9VX/Rvo2uriy5lkkjl/RpI1C2VuI6smsvZn0p9Vk5Fu8TQXw5xqFvId45mBpMwdTDdkqttM1P5CDEaawJV12hcFIes8Kwajf4xnJiNljuQEMVXTUR078sMbZX3h0HQkTce3t+FHsbi6a8xlihskODeEyWEIGx6s2yvH9ovyeKE+f1LW70RZjb3OUcIMM8zwVulN7jIl2jVY3hrJ9iPSqiUtz8S7hnz/yjarsbwIx4/x/BjXj7HvWG4rDGeF1G/YNx/sTx1ZeWYZFQSbnEW4Y7Hqyx4TbAp0ESCJvrA4kRTNUNZ/gVhE2P72YWKxHSpVw+MX7Q7ra20u5g4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52ddee8caedd1e902c0f973f1ba06521/e4706/INSTAR_IP_Camera_Youtube_Streaming_01.avif 230w", "/en/static/52ddee8caedd1e902c0f973f1ba06521/d1af7/INSTAR_IP_Camera_Youtube_Streaming_01.avif 460w", "/en/static/52ddee8caedd1e902c0f973f1ba06521/1824e/INSTAR_IP_Camera_Youtube_Streaming_01.avif 813w"],
              "sizes": "(max-width: 813px) 100vw, 813px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/52ddee8caedd1e902c0f973f1ba06521/a0b58/INSTAR_IP_Camera_Youtube_Streaming_01.webp 230w", "/en/static/52ddee8caedd1e902c0f973f1ba06521/bc10c/INSTAR_IP_Camera_Youtube_Streaming_01.webp 460w", "/en/static/52ddee8caedd1e902c0f973f1ba06521/90602/INSTAR_IP_Camera_Youtube_Streaming_01.webp 813w"],
              "sizes": "(max-width: 813px) 100vw, 813px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52ddee8caedd1e902c0f973f1ba06521/81c8e/INSTAR_IP_Camera_Youtube_Streaming_01.png 230w", "/en/static/52ddee8caedd1e902c0f973f1ba06521/08a84/INSTAR_IP_Camera_Youtube_Streaming_01.png 460w", "/en/static/52ddee8caedd1e902c0f973f1ba06521/baaa6/INSTAR_IP_Camera_Youtube_Streaming_01.png 813w"],
              "sizes": "(max-width: 813px) 100vw, 813px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/52ddee8caedd1e902c0f973f1ba06521/baaa6/INSTAR_IP_Camera_Youtube_Streaming_01.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After downloading the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.exe`}</code>{` file you can install on Windows. For alternative operating systems please refer to the `}<a parentName="p" {...{
        "href": "https://github.com/obsproject/obs-studio/wiki/Install-Instructions"
      }}>{`official documentation`}</a>{`.`}</p>
    <h2 {...{
      "id": "adding-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-cameras",
        "aria-label": "adding your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your Cameras`}</h2>
    <p>{`After starting the software first start a Scene (1):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c9d8794aa619bcced0c8a79f00972d5/302a4/INSTAR_IP_Camera_Youtube_Streaming_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABFUlEQVQY003LSW6DMACFYc4RBRtIgRhDPODZxoxVQ3bJprvc/xhtU1Wq9Ovpbb5kW7dpnOIQnXVKKa21VloKKYU0SiPCIO7qjljRB+vmeYlDvO235/P5eDwSwXtCaNd29EKklMYYzjillAtOGKkJLTp6JtQpGawLIWil52m+3+/7vicwyyGAEMAsyyHMIIAgBXXTIa6K5pKjS9GQElMlhDPGWfe/xMD8cEyPKSAw61JwOKbgmNYII8ZPTfsTbuu2FYw6pZ3zv8y/TvKJcFGfT2U1V+cN4RI19VuFe4rfdTXyMrIqMjXxQTIvpH1Ja6w19geHOMYweOet80McxzgGH5R2vfLiL6n99eO6zMu2bq9d12X9xl+8AkkQa9cxggAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c9d8794aa619bcced0c8a79f00972d5/e4706/INSTAR_IP_Camera_Youtube_Streaming_02.avif 230w", "/en/static/6c9d8794aa619bcced0c8a79f00972d5/d1af7/INSTAR_IP_Camera_Youtube_Streaming_02.avif 460w", "/en/static/6c9d8794aa619bcced0c8a79f00972d5/7f308/INSTAR_IP_Camera_Youtube_Streaming_02.avif 920w", "/en/static/6c9d8794aa619bcced0c8a79f00972d5/d8ca6/INSTAR_IP_Camera_Youtube_Streaming_02.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c9d8794aa619bcced0c8a79f00972d5/a0b58/INSTAR_IP_Camera_Youtube_Streaming_02.webp 230w", "/en/static/6c9d8794aa619bcced0c8a79f00972d5/bc10c/INSTAR_IP_Camera_Youtube_Streaming_02.webp 460w", "/en/static/6c9d8794aa619bcced0c8a79f00972d5/966d8/INSTAR_IP_Camera_Youtube_Streaming_02.webp 920w", "/en/static/6c9d8794aa619bcced0c8a79f00972d5/260c2/INSTAR_IP_Camera_Youtube_Streaming_02.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c9d8794aa619bcced0c8a79f00972d5/81c8e/INSTAR_IP_Camera_Youtube_Streaming_02.png 230w", "/en/static/6c9d8794aa619bcced0c8a79f00972d5/08a84/INSTAR_IP_Camera_Youtube_Streaming_02.png 460w", "/en/static/6c9d8794aa619bcced0c8a79f00972d5/c0255/INSTAR_IP_Camera_Youtube_Streaming_02.png 920w", "/en/static/6c9d8794aa619bcced0c8a79f00972d5/302a4/INSTAR_IP_Camera_Youtube_Streaming_02.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c9d8794aa619bcced0c8a79f00972d5/c0255/INSTAR_IP_Camera_Youtube_Streaming_02.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then add your camera sources via the `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9020_HD/Video_Streaming/"
      }}>{`RTSP video streams`}</a>{` (2):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fe6bc10891ef871a4a49200524c990c1/23266/INSTAR_IP_Camera_Youtube_Streaming_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABVElEQVQY02XQyW7bMBAGYL1HJVJBYFniImqJqI3kkCO7ll2nCBIkaVH03vd/gsb1IW0DfBjMZRb8ke607nRd1brTwQf04Xg4LIj4p0cfQggY8H94qVFK05v0JqWpFBIczA6evv88nb/6ZeeXHe73wYd5mv82jdM4jOBcRAhNCU3iRHCBuPwC/NaZH/34XDWvdftQKiXL8l9CSM64mU1ECaWEkoRwxufZPIBfdQ+lCnWDVeNUzRn/iBVMd/oynNI0iRMp5HpYPS6Pzy+n8/16Oh+/3O/2BzPbd8ZaYz2E4DH4EMWfYpKQJE6yTXZNjuWFYLxr27apSsmVkkoJpUSlpJJy0D04AOfBQXT9YZtt8zwv8oIVrMiLze1Gurti1Rk22dJssNn62n1uj+7ONq3Wutf90A+RNdbM5hqjh8s+a+w0TlWtVaWrenjXDB4CADjrMODb5d9fPGVBKTCrogAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe6bc10891ef871a4a49200524c990c1/e4706/INSTAR_IP_Camera_Youtube_Streaming_03.avif 230w", "/en/static/fe6bc10891ef871a4a49200524c990c1/d1af7/INSTAR_IP_Camera_Youtube_Streaming_03.avif 460w", "/en/static/fe6bc10891ef871a4a49200524c990c1/7f308/INSTAR_IP_Camera_Youtube_Streaming_03.avif 920w", "/en/static/fe6bc10891ef871a4a49200524c990c1/fbc96/INSTAR_IP_Camera_Youtube_Streaming_03.avif 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fe6bc10891ef871a4a49200524c990c1/a0b58/INSTAR_IP_Camera_Youtube_Streaming_03.webp 230w", "/en/static/fe6bc10891ef871a4a49200524c990c1/bc10c/INSTAR_IP_Camera_Youtube_Streaming_03.webp 460w", "/en/static/fe6bc10891ef871a4a49200524c990c1/966d8/INSTAR_IP_Camera_Youtube_Streaming_03.webp 920w", "/en/static/fe6bc10891ef871a4a49200524c990c1/e5eeb/INSTAR_IP_Camera_Youtube_Streaming_03.webp 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe6bc10891ef871a4a49200524c990c1/81c8e/INSTAR_IP_Camera_Youtube_Streaming_03.png 230w", "/en/static/fe6bc10891ef871a4a49200524c990c1/08a84/INSTAR_IP_Camera_Youtube_Streaming_03.png 460w", "/en/static/fe6bc10891ef871a4a49200524c990c1/c0255/INSTAR_IP_Camera_Youtube_Streaming_03.png 920w", "/en/static/fe6bc10891ef871a4a49200524c990c1/23266/INSTAR_IP_Camera_Youtube_Streaming_03.png 923w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fe6bc10891ef871a4a49200524c990c1/c0255/INSTAR_IP_Camera_Youtube_Streaming_03.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`RTSP Stream 1`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/11`}</code></li>
      <li parentName="ul"><strong parentName="li">{`RTSP Stream 2`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/12`}</code></li>
      <li parentName="ul"><strong parentName="li">{`RTSP Stream 3`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/13`}</code></li>
    </ul>
    <p>{`User and password are your camera login - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rtsp://admin:instar@192.168.1.25:554/12`}</code>{`.`}</p>
    <p>{`Please add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`x`}</code>{` according to the IP of your camera (e.g. for the AVM Fritzbox the first x = `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`178`}</code>{` and the second x = is the EndIP your camera receives from your router). If you have changed the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`RTSP port`}</a>{` (default = `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`554`}</code>{`), you have to add it to the IP address of your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2d374ad09980d55a65d3c15267fa5934/97bfd/INSTAR_IP_Camera_Youtube_Streaming_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADVUlEQVQozyWSzU/iCADF+4+MRNCZQXCEHYEFrG2BUpTUltIAFeQrlvKhg0EHWjAKdUlAQgUhEKJ40QMXwRgvOwcTE/VijEf/A++ePO5G/eVdX15e3gN+b/0WeH67UBBFsVQsiSVxd3e3WCyW/ykXd4sCL3wqn8/zOb7wgcAL2Ww2l80BTqdTEISTk5M/f/49Ojq6uLh4fHx8eHi4urr6tf4rFosRS4TJaKIoCgTBaDTKcRzLsn6/PxKOADiO8zxfr9clSUomk+Vy+ebm5v7+vtvthkKhtbW1VCoFwzBFUVarlWVZu93+Hi4IpVIJMJvNMAw7nc5wOIwgCEEQvV7v+Pg4nU4vLy97PJ7hcFgqlTiOi0ajNE1DEBSLxRKJhCiKgF6v//Sk02kYhkmS7HQ67XY7FApFIhGGYUaj0eXlpd/v5zguHo+TJCmTyUwmkyRJgNFodLlcBEG4XC6j0ehwOA4ODhqNhtfrDYVCm5uboiheX1/n83mtVru1tcXzPEVRLpcrkUgABoMBRdGFhQWHw6HRaGw2W6VS6XQ60WjU5/OtfXB2dlatVgOBAMuysVhsfX19ZWUFwzAAQRCapjOZzMbGBoqigiA8PT29vLw8Pz/zPB8IBIbDYbPZrNVqvV6v0WiUy2VRFFOplNvtBsA5MJFItFqt09NTp9NZqVReX1//+6DZbAaDwbu7u3a73el0BoNBv9+XJKlareZyOa/XC6Aourq6enh4OBqNgsGgz+fr9/u3t7dvb2+SJOE4PhgMPoucn5/XajVRFFut1vb29rvZbDajKMqybDwep2maoiiapt1uN8dxDMPgOF6v13me39nZ2d/fJwjCYrF0u929vT2GYQCdTjc7OwtBEIZhOI7TNO33+xkf46E9kXDkc9VCvpDPF4rFYjabTaVSmUwmmUySJAnAMIIgCATB8+A8AiFWixUCIavNanVYQQSELTCKoTabxW6z2m02knTRbjdqQxcXFx0YBmh+aCYUE/JxuVKpnAfnNTOaaZX67zlQOauTq38oVDPjymn5d/XPv7QmgwFBLDAEYxjm8/lomgbGvozJxmRjX8YUcsW3r98mJyYV4/LvU+rJ6RnFlFqhUsunVF9V6p9ajVGnB+dAg94AQ+9XX1pa+h+DXmf46uhaLgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d374ad09980d55a65d3c15267fa5934/e4706/INSTAR_IP_Camera_Youtube_Streaming_04.avif 230w", "/en/static/2d374ad09980d55a65d3c15267fa5934/d1af7/INSTAR_IP_Camera_Youtube_Streaming_04.avif 460w", "/en/static/2d374ad09980d55a65d3c15267fa5934/7f308/INSTAR_IP_Camera_Youtube_Streaming_04.avif 920w", "/en/static/2d374ad09980d55a65d3c15267fa5934/006c2/INSTAR_IP_Camera_Youtube_Streaming_04.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2d374ad09980d55a65d3c15267fa5934/a0b58/INSTAR_IP_Camera_Youtube_Streaming_04.webp 230w", "/en/static/2d374ad09980d55a65d3c15267fa5934/bc10c/INSTAR_IP_Camera_Youtube_Streaming_04.webp 460w", "/en/static/2d374ad09980d55a65d3c15267fa5934/966d8/INSTAR_IP_Camera_Youtube_Streaming_04.webp 920w", "/en/static/2d374ad09980d55a65d3c15267fa5934/cff29/INSTAR_IP_Camera_Youtube_Streaming_04.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d374ad09980d55a65d3c15267fa5934/81c8e/INSTAR_IP_Camera_Youtube_Streaming_04.png 230w", "/en/static/2d374ad09980d55a65d3c15267fa5934/08a84/INSTAR_IP_Camera_Youtube_Streaming_04.png 460w", "/en/static/2d374ad09980d55a65d3c15267fa5934/c0255/INSTAR_IP_Camera_Youtube_Streaming_04.png 920w", "/en/static/2d374ad09980d55a65d3c15267fa5934/97bfd/INSTAR_IP_Camera_Youtube_Streaming_04.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2d374ad09980d55a65d3c15267fa5934/c0255/INSTAR_IP_Camera_Youtube_Streaming_04.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "connecting-to-your-youtube-account",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connecting-to-your-youtube-account",
        "aria-label": "connecting to your youtube account permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting to your Youtube Account`}</h2>
    <p>{`Now we click on “Settings” on the far lower right.  This brings us to the bulk of the settings for OBS.`}</p>
    <p>{`The streaming tab is critical for using Youtube and other similar services. You must provide your server name and your own personal stream key. This key is to be found on the Youtube live streaming page:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f21b4f592971336a76d14b436167ca34/5caea/INSTAR_IP_Camera_Youtube_Streaming_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABDklEQVQY043M30rCcADF8T1HKMjcUtNNXSuds1AJW+A2LSvSYa+REL2H+0kF5f70GL3YNxzRndLFh3NzzpG+o5iPJOErSUnTlDiOSZKEzeeGKIr+JY7iLLd7STy/EIo1619CiEy4ChGh+LPt7LIKBa9v7yyflkj3t3c4w0umN1OCecDsYcZi8cg8CPAnY1zfY+y5TLzRbu6Ia99l7HtIJy2LolykVq1hGiZG08A0DKadCxztjHO5QfnI5kDvk9d65PQdtB5100JqnbZRFTU76tpdOlYn4/SHXA0cmhUdWS5TUKsUlD3UKo3mMdL2KJ/LU9fr2B0bq21lev0Btm1TrlRQFYWSonC4R0lV0WoaP1Nw9fGDqSSCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f21b4f592971336a76d14b436167ca34/e4706/INSTAR_IP_Camera_Youtube_Streaming_05.avif 230w", "/en/static/f21b4f592971336a76d14b436167ca34/d1af7/INSTAR_IP_Camera_Youtube_Streaming_05.avif 460w", "/en/static/f21b4f592971336a76d14b436167ca34/7f308/INSTAR_IP_Camera_Youtube_Streaming_05.avif 920w", "/en/static/f21b4f592971336a76d14b436167ca34/c91b9/INSTAR_IP_Camera_Youtube_Streaming_05.avif 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f21b4f592971336a76d14b436167ca34/a0b58/INSTAR_IP_Camera_Youtube_Streaming_05.webp 230w", "/en/static/f21b4f592971336a76d14b436167ca34/bc10c/INSTAR_IP_Camera_Youtube_Streaming_05.webp 460w", "/en/static/f21b4f592971336a76d14b436167ca34/966d8/INSTAR_IP_Camera_Youtube_Streaming_05.webp 920w", "/en/static/f21b4f592971336a76d14b436167ca34/ef2bd/INSTAR_IP_Camera_Youtube_Streaming_05.webp 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f21b4f592971336a76d14b436167ca34/81c8e/INSTAR_IP_Camera_Youtube_Streaming_05.png 230w", "/en/static/f21b4f592971336a76d14b436167ca34/08a84/INSTAR_IP_Camera_Youtube_Streaming_05.png 460w", "/en/static/f21b4f592971336a76d14b436167ca34/c0255/INSTAR_IP_Camera_Youtube_Streaming_05.png 920w", "/en/static/f21b4f592971336a76d14b436167ca34/5caea/INSTAR_IP_Camera_Youtube_Streaming_05.png 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f21b4f592971336a76d14b436167ca34/c0255/INSTAR_IP_Camera_Youtube_Streaming_05.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can find your `}<strong parentName="p">{`Streaming Key`}</strong>{` by heading over to the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/live_dashboard"
      }}>{`Youtube Dashboard`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b4e0ac2639d176c32d99608b035d69bd/302a4/INSTAR_IP_Camera_Youtube_Streaming_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA00lEQVQY04XQwWrDMAwGYL//o+zSyzK6PMTYdVC7DArNypJS25JsWXJG3GaM0bEP4YPgl4VMYV5qJSK6KqUAACLSccgfJwDw3otIbZjZwOEQ9+8cglT9Fb5iZilyHj+Zeb6pzHkcRwNEGWCudf6D1kqZEUlVrx3RQoRIaF67x4fN5qnrnrfbvu+H4VhKSSl9h9uOemeoqjm9veycddY5Z63dTdMUYwwhEC1fLbnmfjgS/hRCuKz8TWiHEFlLq9a5ioi5/Md7nzkRY2Ki3N4EZz9FiF/2NpLjolMU9AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b4e0ac2639d176c32d99608b035d69bd/e4706/INSTAR_IP_Camera_Youtube_Streaming_06.avif 230w", "/en/static/b4e0ac2639d176c32d99608b035d69bd/d1af7/INSTAR_IP_Camera_Youtube_Streaming_06.avif 460w", "/en/static/b4e0ac2639d176c32d99608b035d69bd/7f308/INSTAR_IP_Camera_Youtube_Streaming_06.avif 920w", "/en/static/b4e0ac2639d176c32d99608b035d69bd/d8ca6/INSTAR_IP_Camera_Youtube_Streaming_06.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b4e0ac2639d176c32d99608b035d69bd/a0b58/INSTAR_IP_Camera_Youtube_Streaming_06.webp 230w", "/en/static/b4e0ac2639d176c32d99608b035d69bd/bc10c/INSTAR_IP_Camera_Youtube_Streaming_06.webp 460w", "/en/static/b4e0ac2639d176c32d99608b035d69bd/966d8/INSTAR_IP_Camera_Youtube_Streaming_06.webp 920w", "/en/static/b4e0ac2639d176c32d99608b035d69bd/260c2/INSTAR_IP_Camera_Youtube_Streaming_06.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b4e0ac2639d176c32d99608b035d69bd/81c8e/INSTAR_IP_Camera_Youtube_Streaming_06.png 230w", "/en/static/b4e0ac2639d176c32d99608b035d69bd/08a84/INSTAR_IP_Camera_Youtube_Streaming_06.png 460w", "/en/static/b4e0ac2639d176c32d99608b035d69bd/c0255/INSTAR_IP_Camera_Youtube_Streaming_06.png 920w", "/en/static/b4e0ac2639d176c32d99608b035d69bd/302a4/INSTAR_IP_Camera_Youtube_Streaming_06.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b4e0ac2639d176c32d99608b035d69bd/c0255/INSTAR_IP_Camera_Youtube_Streaming_06.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Copy personal Key to the corresponding field in the OBS settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/147418825d1ddc594236cd807bb54f06/d30ee/INSTAR_IP_Camera_Youtube_Streaming_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAApklEQVQI113NSw6CQBAE0DmHJLLVhRCB+UD3NDEMnyERDoobCTIczwAuiJW3qnS62DC8xvf4mSY3u9WWxS27X3mwltuBmx3jXHgn73q5JnES3SOecNJUVVVdN6YwOVFO+RECIiBkAACM6BHeAp5wBMzSzBSmNGVd1baxxpRSyCMlVWvb1rZ913fPjsVx7J/9MAgRMFXp/kJJpaSSQgou/uzLpEmj/gJBMVPLCJNifgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/147418825d1ddc594236cd807bb54f06/e4706/INSTAR_IP_Camera_Youtube_Streaming_07.avif 230w", "/en/static/147418825d1ddc594236cd807bb54f06/d1af7/INSTAR_IP_Camera_Youtube_Streaming_07.avif 460w", "/en/static/147418825d1ddc594236cd807bb54f06/7f308/INSTAR_IP_Camera_Youtube_Streaming_07.avif 920w", "/en/static/147418825d1ddc594236cd807bb54f06/b84c9/INSTAR_IP_Camera_Youtube_Streaming_07.avif 980w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/147418825d1ddc594236cd807bb54f06/a0b58/INSTAR_IP_Camera_Youtube_Streaming_07.webp 230w", "/en/static/147418825d1ddc594236cd807bb54f06/bc10c/INSTAR_IP_Camera_Youtube_Streaming_07.webp 460w", "/en/static/147418825d1ddc594236cd807bb54f06/966d8/INSTAR_IP_Camera_Youtube_Streaming_07.webp 920w", "/en/static/147418825d1ddc594236cd807bb54f06/58b01/INSTAR_IP_Camera_Youtube_Streaming_07.webp 980w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/147418825d1ddc594236cd807bb54f06/81c8e/INSTAR_IP_Camera_Youtube_Streaming_07.png 230w", "/en/static/147418825d1ddc594236cd807bb54f06/08a84/INSTAR_IP_Camera_Youtube_Streaming_07.png 460w", "/en/static/147418825d1ddc594236cd807bb54f06/c0255/INSTAR_IP_Camera_Youtube_Streaming_07.png 920w", "/en/static/147418825d1ddc594236cd807bb54f06/d30ee/INSTAR_IP_Camera_Youtube_Streaming_07.png 980w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/147418825d1ddc594236cd807bb54f06/c0255/INSTAR_IP_Camera_Youtube_Streaming_07.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now click on `}<strong parentName="p">{`Start Streaming`}</strong>{` to push your life stream to Youtube:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dfff5675e6093283ca1a0c758b496b94/e3829/INSTAR_IP_Camera_Youtube_Streaming_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.39130434782608%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADUElEQVQozz3PS0/qaADG8X4I3ZDRHm9zALkcKA5IoeVtKS3n0HIpvCKZtCy4CYTLyJjagsABEze404wmRmJiXJnoLDirWehS496l0YWJn8Gkk+Mk88s/edYP0tpsKYqiqVqv1++0OzudnV6v9/37YDgcdne6mqr9X1trd9qdTrujaZq6rW5vbyOKolxcXEwmk7u7u6Ojox+TH9fX1+PxuN/vQwgJgpBl2e12T09P22w2URTT6XQsFuM4DgCATCYTXdff3991XX96enp7e3t4eBiNRicnJwcHB4Ig7O3txeNxg8GwurpaKpWazWatVsvn8xBC5Ozs7OXl5fX1Vdf1q6ur09PT8/NzRVEajYaqqhRFtVothmFQFPX5fMVisV6vl8vlXC6XTqcRWZaLxeLl5aWu671er1arjUajZrMZjUYlScJxvFAo4DhuNBoBANlstlAoNBqNSqUiyzISiUQymYymaYeHh/+dGQ6H9Xo9kUgkk0kMw8LhsNPpRFGUoqhyuQwhDIVCkiQVCgUkGAzGYjFJkmRZdrlcHMcNBoNWq7W2tiaKosPhIEnSZrMZDAaGYQaDAcdxCILgOL6xsfFzstmsoiiqqgIAKpXKPx/G43GpVAIA5PN5mqbNZjPP8xBCURQDgQAAoFqtIh63J5VKbf6xORqNSJLM5XL39/fPz8+Pj4/tdpvn+U6nE4lE4vF4tVplWdZkMjEMk81mJUlCOI6TZXl/f//m5gZCKAhCv98/Pj6+vb3tdrvRaHR3d5dlWQAAhJAkSZZlw+EwQRBerxfx+/3hr1+3trZUVY19SKVSQlRIJpPRD5VKheO4mZmZqakpp8PBh8OYw4GiKE3TyMqKC8MwgiBYluV5XhTF9fX1dDotimK1Ut36cwtC6Pf5MQwDAOSKJVeQNjmdVovFbDYjBBEIBCiCDPj9BAiAIB0kCIKmaSpIkYAMcSE2zFJUIAhIhgn9zgt/OVYabi/z7RsXCiE2l3d2yfLLgnnJZPf7/Hab3fTZuOJZNX5xzZmtPzNZF0zWFSfm9qwmSfD3Z+vQ40tkMlBMIujMLDqLojOz83PzS4tLC3Pzn9BPRovlV8y2aF9etC/P28zmL5bflo24w+n1E3aPBw+ARCIhCPy/yxVIb7FglV0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfff5675e6093283ca1a0c758b496b94/e4706/INSTAR_IP_Camera_Youtube_Streaming_08.avif 230w", "/en/static/dfff5675e6093283ca1a0c758b496b94/d1af7/INSTAR_IP_Camera_Youtube_Streaming_08.avif 460w", "/en/static/dfff5675e6093283ca1a0c758b496b94/7f308/INSTAR_IP_Camera_Youtube_Streaming_08.avif 920w", "/en/static/dfff5675e6093283ca1a0c758b496b94/969b5/INSTAR_IP_Camera_Youtube_Streaming_08.avif 1077w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dfff5675e6093283ca1a0c758b496b94/a0b58/INSTAR_IP_Camera_Youtube_Streaming_08.webp 230w", "/en/static/dfff5675e6093283ca1a0c758b496b94/bc10c/INSTAR_IP_Camera_Youtube_Streaming_08.webp 460w", "/en/static/dfff5675e6093283ca1a0c758b496b94/966d8/INSTAR_IP_Camera_Youtube_Streaming_08.webp 920w", "/en/static/dfff5675e6093283ca1a0c758b496b94/f0bfb/INSTAR_IP_Camera_Youtube_Streaming_08.webp 1077w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfff5675e6093283ca1a0c758b496b94/81c8e/INSTAR_IP_Camera_Youtube_Streaming_08.png 230w", "/en/static/dfff5675e6093283ca1a0c758b496b94/08a84/INSTAR_IP_Camera_Youtube_Streaming_08.png 460w", "/en/static/dfff5675e6093283ca1a0c758b496b94/c0255/INSTAR_IP_Camera_Youtube_Streaming_08.png 920w", "/en/static/dfff5675e6093283ca1a0c758b496b94/e3829/INSTAR_IP_Camera_Youtube_Streaming_08.png 1077w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dfff5675e6093283ca1a0c758b496b94/c0255/INSTAR_IP_Camera_Youtube_Streaming_08.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Return to the `}<a parentName="p" {...{
        "href": "www.youtube.com/live_dashboard"
      }}>{`Youtube Dashboard`}</a>{` to see that your live now:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/99661/INSTAR_IP_Camera_Youtube_Streaming_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACFklEQVQozzXOyU8TUQDH8flfBEWNXNREYlvBmJaGA3TRFllc2illMbFFy8FEKQkH27QxeEESgR7kZjx4NCQmJARJoBdqbaCx43Qm02S29+bN2pk+TdXP4Xf7Jj+iLtZZnhWhpFt6u9N2sGPapo1tzdQkJFuOZdiGYqiabeiOKSkyzfwCCHA8BxRIHJwcfG9Wjs6OKnRl/2S/9Km0vrO++nZ15c3K1sette21nc8fOImiW6fN1ikjUQxiWJVlENPkm4SiIIxx7Udt7+teIV9YfLpIxuIDNwYuXbw8l5yLRsZfvXhpCQA0KEhRpgwwxtjpYIwlUSKKheLxcbm0XcouZ1OpdDQSjUbG3S632+VemH+STMwUi0VB7fCioKiq4zi4y3EcURSJRw8eLswvjPhHEmRiNjnr8/pcN119F/puDw2RMbK353zudQ6ZWBB4hJBt21YXxhhCSJBxMvM8M+zzb77fPPx2mE6l74bvee94x0YDgbHgtavX87k8hLAbqwghVVUVRbFt+1+88W4jFotnnmV2v+xml7MzZDIcCvuH/YO3Bt0uz1JmSYEKzwsQQggggBAAoKoIAECEQ+GpyamJ+xPBQDAYCE5PTifIhMft6b/SHwqEes71xh7HrHYHQmDohq7rf8e0TFmWiUqlUi6Xa7VatVqlGlSr1eI4rvGzwXEcy7L1szpN05IMJUnS/9M0zTTMP7d/A6+SgM9jmguFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20c10e078eb4a0225c1f3c39d8a3e000/e4706/INSTAR_IP_Camera_Youtube_Streaming_09.avif 230w", "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/d1af7/INSTAR_IP_Camera_Youtube_Streaming_09.avif 460w", "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/7f308/INSTAR_IP_Camera_Youtube_Streaming_09.avif 920w", "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/2fdb2/INSTAR_IP_Camera_Youtube_Streaming_09.avif 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/20c10e078eb4a0225c1f3c39d8a3e000/a0b58/INSTAR_IP_Camera_Youtube_Streaming_09.webp 230w", "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/bc10c/INSTAR_IP_Camera_Youtube_Streaming_09.webp 460w", "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/966d8/INSTAR_IP_Camera_Youtube_Streaming_09.webp 920w", "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/4eac3/INSTAR_IP_Camera_Youtube_Streaming_09.webp 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20c10e078eb4a0225c1f3c39d8a3e000/81c8e/INSTAR_IP_Camera_Youtube_Streaming_09.png 230w", "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/08a84/INSTAR_IP_Camera_Youtube_Streaming_09.png 460w", "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/c0255/INSTAR_IP_Camera_Youtube_Streaming_09.png 920w", "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/99661/INSTAR_IP_Camera_Youtube_Streaming_09.png 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/20c10e078eb4a0225c1f3c39d8a3e000/c0255/INSTAR_IP_Camera_Youtube_Streaming_09.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "vlc-video-source",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#vlc-video-source",
        "aria-label": "vlc video source permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VLC Video Source`}</h2>
    <p>{`If you experience stream cut-offs it often helps to stream your camera live feed through the `}<a parentName="p" {...{
        "href": "/en/Software/Windows/VLC_Player/"
      }}>{`VLC Player`}</a>{` to OBS. To do this make sure that you have downloaded and installed `}<a parentName="p" {...{
        "href": "https://www.videolan.org/vlc/download-windows.html"
      }}>{`the VLC Player`}</a>{` first.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b6cdba63d292454e2fdba09129802e2/e3829/INSTAR_IP_Camera_Youtube_Streaming_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABUUlEQVQY002R2W7bMBQF9RtNTFJLLGr3qoWXvKRE25XcKHBsFxEQ/0OAvvT7i9RAEGBe52CA4xhtWmMQUWs9Po/73V6ClADaoELZdqazrUapUaHCnd0dDntEtJ3t2tYRdZPECQ95USzH55dfwxGlqgEKKfhmFVfrqNzk5Votc1NW/XAc+n4Yhultul6uju/5jDFKKA/56XwejgNIsdxuwnwRxKnPEzeMgihZZxlsS0RdlZW1dpqmy+XiUEIpobMZ4UFw60/n/euLGfUW6TzyeOrx1A1Tn6dZlq0WSwkSBABA13bGGIdRxiibERoF/vt4e/39cbr+wbonDz8YoYyQO48Pj3EUK6k+ZQFN3YhGOJ7rea7nUpY/hX3/07ZaQpXlKSGUUvYFmZG7LBpx90GA09SfK7De/E3yg+k6uwMhV4vV/GkezsPvFHmhFP7/Qt77/wGB5FZTaSbZCwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b6cdba63d292454e2fdba09129802e2/e4706/INSTAR_IP_Camera_Youtube_Streaming_10.avif 230w", "/en/static/2b6cdba63d292454e2fdba09129802e2/d1af7/INSTAR_IP_Camera_Youtube_Streaming_10.avif 460w", "/en/static/2b6cdba63d292454e2fdba09129802e2/7f308/INSTAR_IP_Camera_Youtube_Streaming_10.avif 920w", "/en/static/2b6cdba63d292454e2fdba09129802e2/969b5/INSTAR_IP_Camera_Youtube_Streaming_10.avif 1077w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b6cdba63d292454e2fdba09129802e2/a0b58/INSTAR_IP_Camera_Youtube_Streaming_10.webp 230w", "/en/static/2b6cdba63d292454e2fdba09129802e2/bc10c/INSTAR_IP_Camera_Youtube_Streaming_10.webp 460w", "/en/static/2b6cdba63d292454e2fdba09129802e2/966d8/INSTAR_IP_Camera_Youtube_Streaming_10.webp 920w", "/en/static/2b6cdba63d292454e2fdba09129802e2/f0bfb/INSTAR_IP_Camera_Youtube_Streaming_10.webp 1077w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b6cdba63d292454e2fdba09129802e2/81c8e/INSTAR_IP_Camera_Youtube_Streaming_10.png 230w", "/en/static/2b6cdba63d292454e2fdba09129802e2/08a84/INSTAR_IP_Camera_Youtube_Streaming_10.png 460w", "/en/static/2b6cdba63d292454e2fdba09129802e2/c0255/INSTAR_IP_Camera_Youtube_Streaming_10.png 920w", "/en/static/2b6cdba63d292454e2fdba09129802e2/e3829/INSTAR_IP_Camera_Youtube_Streaming_10.png 1077w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b6cdba63d292454e2fdba09129802e2/c0255/INSTAR_IP_Camera_Youtube_Streaming_10.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Similar to Media Source, you can add video and other media files to this source to be played in your scenes. This source type will use the VLC libraries for extended media support over the built-in Media Source. It requires that VLC be installed on your system to show up as an available source in OBS. If you are using 64bit OBS, you must install 64bit VLC and if you are using 32bit OBS, you must install 32bit VLC.`}</p>
    <p>{`To add add your cameras `}<a parentName="p" {...{
        "href": "#adding-your-cameras"
      }}>{`RTSP Streaming URL`}</a>{`, click the + sign and type in the URL that you wish to add.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/097cdd151fd238b826805de9631bb5e3/302a4/INSTAR_IP_Camera_Youtube_Streaming_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABU0lEQVQY0z2Py07CQABF+xlGOo/OFFrKdLCldF4t2MeILATCxiX/wU+4kJ2aYCKRhf6j0arJyc3NTc7iOkbraTa11h4eD89PL8fj6/n9fHo7fX587vd7qYRSucynWqq6qld3qxt7Y63drDdN0ziUUOKRMAhta40uYhZ3cBaPxpwkbJDyacqKMJzPr5uqbqp6tV7vdrvl8tbBCCOI+n5/kk54zINB8MdgOOKEpWSUJGk2k0JrI4WSQi4Wi+1227atgyCCAPrU97AHXAAB/IcSH2OKEPE86lNf5MJoo5U22nTFgQACF1BCEUTuZa8HwMXPAlxAPIIRxhAhCDHCUsjO+ef7s4e9YBBIIZN0kjNuw2gc8+QqiYaR23OBC7oUudBKK6mUVL9yYQollchFaQpRlPdZ/sCSZn7dtK3MRTSM2IiN+TibZGVR1lVdlrO6qmflzEj1BeIlWUlFw1e+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/097cdd151fd238b826805de9631bb5e3/e4706/INSTAR_IP_Camera_Youtube_Streaming_11.avif 230w", "/en/static/097cdd151fd238b826805de9631bb5e3/d1af7/INSTAR_IP_Camera_Youtube_Streaming_11.avif 460w", "/en/static/097cdd151fd238b826805de9631bb5e3/7f308/INSTAR_IP_Camera_Youtube_Streaming_11.avif 920w", "/en/static/097cdd151fd238b826805de9631bb5e3/d8ca6/INSTAR_IP_Camera_Youtube_Streaming_11.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/097cdd151fd238b826805de9631bb5e3/a0b58/INSTAR_IP_Camera_Youtube_Streaming_11.webp 230w", "/en/static/097cdd151fd238b826805de9631bb5e3/bc10c/INSTAR_IP_Camera_Youtube_Streaming_11.webp 460w", "/en/static/097cdd151fd238b826805de9631bb5e3/966d8/INSTAR_IP_Camera_Youtube_Streaming_11.webp 920w", "/en/static/097cdd151fd238b826805de9631bb5e3/260c2/INSTAR_IP_Camera_Youtube_Streaming_11.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/097cdd151fd238b826805de9631bb5e3/81c8e/INSTAR_IP_Camera_Youtube_Streaming_11.png 230w", "/en/static/097cdd151fd238b826805de9631bb5e3/08a84/INSTAR_IP_Camera_Youtube_Streaming_11.png 460w", "/en/static/097cdd151fd238b826805de9631bb5e3/c0255/INSTAR_IP_Camera_Youtube_Streaming_11.png 920w", "/en/static/097cdd151fd238b826805de9631bb5e3/302a4/INSTAR_IP_Camera_Youtube_Streaming_11.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/097cdd151fd238b826805de9631bb5e3/c0255/INSTAR_IP_Camera_Youtube_Streaming_11.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And now add the RTSP URL you want to stream from:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`RTSP Stream 1`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/11`}</code></li>
      <li parentName="ul"><strong parentName="li">{`RTSP Stream 2`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/12`}</code></li>
      <li parentName="ul"><strong parentName="li">{`RTSP Stream 3`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/13`}</code></li>
    </ul>
    <p>{`User and password are your camera login - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rtsp://admin:instar@192.168.2.116:554/12`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6d4c27a257a5d430717db236ccd22be4/f5209/INSTAR_IP_Camera_Youtube_Streaming_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC4UlEQVQoz0WOX0/aagDG+y2WJROPLlnrnMMJ0r8vLbS0L7wtJbQopMhhK8F/ySBWkA1IlDrNMnO8mA4TpxxdNCEyJWcXB7+A8fbsws+zGJOzJ8/N7+KX58GajgNjsdvb25ubm7u7u06nY5qm67qO4ywsLNTr9Vwup2maZVmmaTqOY9t2sVg8Pj7+UHuHfWq1WAAODg76/f7g38GPf358733v9XpXV1eXl5eDwaDX611cXJydnZ2cnHS7XZ7no9Ho6enph3oDO9rbSySTiixDBZqGWS6Xt7e3o9GoLMvJZLJcLlcqlWq1WlmtrK2tIYQ8Qx5BEM7Pz7caDezr58/vGw2/z6+q6sOxZrOp67qiKAihubk5WZY3Njaazabruqqq+n2+cDi86jilYhE773T+/vZNkiRN09Lp9O7u7vX19fLycj6fT6VSiUQCIbS4uOg4zubmJoRwfn5+tVJ5/eZN1jSx0729vz5+1BGKx+OFQqHVav3876fbcm3brlarlmWJojg6OhqLxXZ2diCEKoRWKmVomiHLmMhyAsOwJKXruuu6Kysr/X6/2+0uLS3l83nXdUulUlyPG4bxtvQWISQGeYFhQgyrQ4h5vZM4Tni9Xgjh+vq6bdu1tdrW1lahUDAMI5vNWpZl23Y2m1UUJXIfOYbUXO7PmXQGG/ljZNgzPEaMAQ6EhJCmapIoUSRFU3RgOuCb8k29mvL7/IADfJDnQZChGTkiz87OJEwTG/YMe4Y8BE4ADkSkSK1Wa7fb+/v7h4eH7Xb7y/6Xo69H9fd1r9c77Z8Oh8Isw7IMy7EclJXfMk3RFEmhGJqdTWfSmdxcLpPJGEljJjUjidLjR489TzyAAyzDMjRDBihJlO7loSdDBE7wQZ6hmYkXEwROjD8ffznxcnJycox4TuAE/gx/OvoUf4YHOUBT9P1MgIxIEewBSPIeAAcoknooGSDJAPk/hoSQKEpRGAUcEHiB44AUFn8BJdUTaMZKEpIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d4c27a257a5d430717db236ccd22be4/e4706/INSTAR_IP_Camera_Youtube_Streaming_12.avif 230w", "/en/static/6d4c27a257a5d430717db236ccd22be4/d1af7/INSTAR_IP_Camera_Youtube_Streaming_12.avif 460w", "/en/static/6d4c27a257a5d430717db236ccd22be4/7f308/INSTAR_IP_Camera_Youtube_Streaming_12.avif 920w", "/en/static/6d4c27a257a5d430717db236ccd22be4/2b0bf/INSTAR_IP_Camera_Youtube_Streaming_12.avif 1061w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6d4c27a257a5d430717db236ccd22be4/a0b58/INSTAR_IP_Camera_Youtube_Streaming_12.webp 230w", "/en/static/6d4c27a257a5d430717db236ccd22be4/bc10c/INSTAR_IP_Camera_Youtube_Streaming_12.webp 460w", "/en/static/6d4c27a257a5d430717db236ccd22be4/966d8/INSTAR_IP_Camera_Youtube_Streaming_12.webp 920w", "/en/static/6d4c27a257a5d430717db236ccd22be4/44ba8/INSTAR_IP_Camera_Youtube_Streaming_12.webp 1061w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d4c27a257a5d430717db236ccd22be4/81c8e/INSTAR_IP_Camera_Youtube_Streaming_12.png 230w", "/en/static/6d4c27a257a5d430717db236ccd22be4/08a84/INSTAR_IP_Camera_Youtube_Streaming_12.png 460w", "/en/static/6d4c27a257a5d430717db236ccd22be4/c0255/INSTAR_IP_Camera_Youtube_Streaming_12.png 920w", "/en/static/6d4c27a257a5d430717db236ccd22be4/f5209/INSTAR_IP_Camera_Youtube_Streaming_12.png 1061w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6d4c27a257a5d430717db236ccd22be4/c0255/INSTAR_IP_Camera_Youtube_Streaming_12.png",
              "alt": "INSTAR IP Camera Youtube Streaming",
              "title": "INSTAR IP Camera Youtube Streaming",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      